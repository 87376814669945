/**
 *
 * This timestamp value is used to get the build time of the current page
 *
 */


export const buildTimestamp = "12-08-2023 12:00:00"//import.meta.env.VITE_BUILD_TIMESTAMP;

const metaTag = document.querySelector('meta[name="build-timestamp"]');
if (metaTag) {
  metaTag.content = buildTimestamp;
}
