import { definePlugin } from '/@src/app'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import { firebaseConfig } from '../../Architecture/Application/Abstractions/Firebase/config'

// https://firebase.google.com/docs/web/setup#available-libraries

export const firebaseSymbol: InjectionKey<
  Awaited<ReturnType<typeof initFirebasefServices>>
> = Symbol('firebase')

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const firestore = getFirestore(app)
export const functions = getFunctions(app)
export const storage = getStorage(app)

if (import.meta.env.DEV) {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  console.log('running firebase emulation (make sure that is running!).')
}

async function initFirebasefServices() {
  // let firebaseServices: any | null // TODO: FIX THIS TYPE

  if (!import.meta.env.SSR) {
    // Initialize Firebase
  }

  const firebaseServices = {
    app,
    auth,
    firestore,
    functions,
    storage,
  }
  return firebaseServices
}

export default definePlugin(async ({ app }) => {
  const firebase = await initFirebasefServices()
  app.provide(firebaseSymbol, firebase)
})
