// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyB9qahdC5q2MnTxJeeG7ROcPm4Jp-Fqm9k',
  authDomain: 'webplatformayc.firebaseapp.com',
  projectId: 'webplatformayc',
  storageBucket: 'webplatformayc.appspot.com',
  messagingSenderId: '1090697334211',
  appId: '1:1090697334211:web:5876d2d013222a65a08176',
  measurementId: 'G-HG4RTBZTNG',
}
