import { FirebaseTimeStampToDate } from '../Abstractions/Firebase/firestore'

const monthsAbbreviated = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const monthsFull = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export function FormatDateWithTimezone(date?: Date, monthAbbr = true) {
  if (date) {
    const year = date.getFullYear()
    const monthAbbreviated = (monthAbbr ? monthsAbbreviated : monthsFull)[date.getMonth()]
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    const timezone = date.toTimeString().split(' ')[1]

    return `${year}-${monthAbbreviated}-${day} ${hours}:${minutes}:${seconds} ${timezone}`
  }
  return ''
}

//for available timezones see: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export function FormatDateTimeShort(date?: Date, timeZone: string = 'America/Bogota') {
  if (date) {
    // If a timeZone is provided, adjust the date and time accordingly
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone,
    }
    const formatter = new Intl.DateTimeFormat('en-US', options)

    return formatter.format(date)
  }
  return ''
}

export function FormatDateOnlyDate(date?: Date, monthAbbr = true) {
  return FormatDateWithTimezone(date, monthAbbr).split(' ')[0]
}

export function TimestampToDate(value: any): Date {
  return FirebaseTimeStampToDate(value)
}

/**
 * @param value - a string that represents a UTC date, ex: "2023-11-02T04:36:39.206Z"
 * @returns a date object
 */
export function UTCStrToDate(value: string): Date {
  return new Date(value)
}
